import * as React from 'react'
import styled from '@emotion/styled'
import { Button } from 'rbx'
import { dimensions } from '../../styles/variables'

export const StyledButton = styled(({ ...props }) => <Button as="a" rounded {...props} />)`
  display: inline-block;
  font-weight: ${dimensions.fontWeight.semibold};
`
export const StyledButtonLink = styled(({ ...props }) => <Button as="a" rounded {...props} />)`
  display: inline-block;
  font-weight: ${dimensions.fontWeight.semibold};
`
