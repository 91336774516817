import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import 'modern-normalize'
import '../styles/normalize'

import BodyFooter from 'components/Footer'
import Header from '../components/Header'
import LayoutRoot from '../components/LayoutRoot'
import LayoutMain from '../components/LayoutMain'
import { PrismicImage } from '../utils/prismic.types'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
  prismicGlobalServe: {
    data: {
      logo: PrismicImage
    }
  }
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
        prismicGlobalServe {
          data {
            logo {
              alt
              copyright
              url
            }
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <LayoutRoot>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
          script={[{ src: '//code.jivosite.com/widget/Mc2SwzaYiQ async' }]}
        />
        <Header logo={data.prismicGlobalServe.data.logo} />
        <LayoutMain>{children}</LayoutMain>
        <BodyFooter title={data.site.siteMetadata.title} />
      </LayoutRoot>
    )}
  />
)

export default IndexLayout
