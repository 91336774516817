import * as React from 'react'
import { Link } from 'gatsby'

import styled from '@emotion/styled'
import { Navbar } from 'rbx'
import { colors, dimensions } from '../styles/variables'
import { StyledButtonLink } from './Button'
import StyledImg from './Img'
import { PrismicImage } from '../utils/prismic.types'
import StyledContainer from './Container'

// const StyledHeader = styled.header`
//   height: ${heights.header}px;
//   padding: 0 ${dimensions.containerPadding}rem;
//   // background-color: ${colors.brand.main};
//   // color: ${transparentize(0.5, colors.white)};
//
//   .menu {
//   display: flex;
//   align-items: center;
//   }
//
//   .cta {
//   padding-left: 3.6rem;
//   }
// `

const HeaderWrapper = styled.div`
  .container {
    display: flex;
    align-items: stretch;
    width: 100%;
  }
`

export const HomepageLink = styled(Link)`
  //color: ${colors.white};
  font-size: 1.5rem;
  font-weight: 600;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`
const HeaderLink = styled(HomepageLink)`
  padding-right: 1.2rem;
  color: ${colors.black};
  font-size: ${dimensions.fontSize.small}px;

  :last-of-type {
    padding-right: 0;
  }
`

interface HeaderProps {
  logo: PrismicImage
}

const Header: React.FC<HeaderProps> = ({ logo }) => (
  <HeaderWrapper>
    <Navbar>
      <StyledContainer>
        <Navbar.Brand>
          <Navbar.Item as={Link} to="/">
            <StyledImg src={logo.url} alt={logo.alt} className="logo" />
          </Navbar.Item>
        </Navbar.Brand>

        <Navbar.Burger />

        <Navbar.Menu>
          <Navbar.Segment align="end">
            <Navbar.Item as="li">
              <HeaderLink to="/features">Features</HeaderLink>
            </Navbar.Item>
            <Navbar.Item as="li">
              <HeaderLink to="/pricing">Pricing</HeaderLink>
            </Navbar.Item>
            <Navbar.Item as="li">
              <HeaderLink to="/support">Support</HeaderLink>
            </Navbar.Item>
            <Navbar.Item as="li">
              <div className="buttons">
                <StyledButtonLink size="small" href={`${process.env.API_URL}/auth/login`}>
                  Login
                </StyledButtonLink>
                <StyledButtonLink color="primary" size="small" href={`${process.env.API_URL}/auth/signup`}>
                  Start free trial
                </StyledButtonLink>
              </div>
            </Navbar.Item>
          </Navbar.Segment>
        </Navbar.Menu>
      </StyledContainer>
    </Navbar>
  </HeaderWrapper>
)

export default Header
