import React from 'react'
import { ImageProps } from 'react-bulma-components'
import { PrismicImage } from '../../utils/prismic.types'

interface ImgProps {
  src?: string
  alt?: string
  className?: string
}

const StyledImg: React.FC<ImgProps> = ({ src, alt, className }) => {
  return <img src={src} alt={alt} className={className} />
}

export default StyledImg
