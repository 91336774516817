import * as React from 'react'
import styled from '@emotion/styled'
import { Container } from 'rbx'

import { breakpoints, widths } from '../styles/variables'
import { getEmSize } from '../styles/mixins'

const Wrapper = styled(Container)`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
  width: auto;
  max-width: ${getEmSize(widths.xl)}em;
`

interface ContainerProps {
  className?: string
}

const StyledContainer: React.FC<ContainerProps> = ({ children, className }) => <Wrapper className={className}>{children}</Wrapper>

export default StyledContainer
