import * as React from 'react'
import { Link } from 'gatsby'

import styled from '@emotion/styled'
import { ScFacebook } from 'styled-icons/evil/ScFacebook'
import { ScTwitter } from 'styled-icons/evil/ScTwitter'
import { ScLinkedin } from 'styled-icons/evil/ScLinkedin'
import { Footer } from 'rbx'
import { dimensions, colors } from '../styles/variables'
import { HomepageLink } from './Header'
import StyledContainer from './Container'

const StyledFooter = styled(Footer)`
  //margin-top: 3.6rem;
  padding: 4.8rem 0 4.8rem;
  background-color: ${colors.gray.copy};
  color: ${colors.gray.calm};

  a {
    color: ${colors.ui.light};
    font-weight: ${dimensions.fontWeight.bold};
  }
`

const FooterInner = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  ${HomepageLink} {
    font-size: ${dimensions.fontSize.regular}px;
  }

  .social {
    display: flex;
    margin-bottom: 0;
    align-items: center;

    li {
      a {
        display: block;
        width: 2rem;
        line-height: 1;
      }
    }

    svg {
      display: inline-block;
      width: 100%;
      margin: 0 1.2rem 0 0;

      line-height: 1;
      color: ${colors.white};
    }
  }
`

const FooterLink = styled(Link)`
  padding-right: 1.2rem;
  font-size: ${dimensions.fontSize.tiny}px;

  :last-of-type {
    padding-right: 0;
  }
`

interface FooterProps {
  title: string
}

const BodyFooter: React.FC<FooterProps> = () => (
  <StyledFooter>
    <StyledContainer>
      <FooterInner>
        <div>
          <HomepageLink to="/">OrderSenta</HomepageLink>
        </div>

        <div>
          <FooterLink to="/pricing">Pricing</FooterLink>
          <FooterLink to="/about">About</FooterLink>
          <FooterLink to="/support">Support</FooterLink>
          <FooterLink to="/contact">Contact</FooterLink>
        </div>

        <ul className="social">
          <li>
            <a href="#!">
              <ScFacebook />
            </a>
          </li>
          <li>
            <a href="#!">
              <ScTwitter />
            </a>
          </li>
          <li>
            <a href="#!">
              <ScLinkedin />
            </a>
          </li>
        </ul>
      </FooterInner>
    </StyledContainer>

    <StyledContainer>
      <hr />
      <small>2019 OrderSenta - All rights reserved.</small>
    </StyledContainer>
  </StyledFooter>
)

export default BodyFooter
